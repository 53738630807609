import React, { useState, useRef, useLayoutEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import AnglesDown from '../../assets/images/angles-down.svg';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);
  

const FontFaceObserver = require('font-face-observer');

const spin = keyframes`
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  50% {
    transform: translateY(20px);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
`;

const CircleArrowContainer = styled.img`
display: inline-block;
width: 20px;
height: auto;
animation: ${spin} 2s ease-in-out infinite;
`;

const ScrollDown = styled.div`
    display: flex;
    gap: 20px;
    margin-left: -2.7vw;
`;

const HomeContentDiv = styled.div`
    box-sizing: border-box;
    max-width: 900px;
    display: grid;
    grid-template-rows: auto;
    row-gap: 30px;
    margin-top: 20px;
`;

const HomeHeading = styled.div`
    font-family: 'Arial-MT-Bold';
    font-size: 18vw;
    z-index:800;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 1;


    @media (min-width: 1000px) {
        font-size: 11vw;
        letter-spacing: -0.5rem;
        margin-left: -10px;
    }

    @media (min-width: 1500px) {
        font-size: 9rem;
    }

    @media (min-width: 1930px) {
        font-size: 10rem;
    }
`;

const HomeParagraph = styled.p`
    font-family: 'Roboto-thin';
    font-size: 1rem;
    line-height: 150%;
    letter-spacing: 0.05rem;
    border-left: 3px solid #6200ff;
    padding: 20px 20px;
    border-radius: 0px 0px 0px 0px;
    max-width: 80%;

    @media (min-width: 1500px) {
        font-size: 1rem;
    }

    @media (min-width: 1930px) {
        font-size: 1.5rem;
        
    }
`;

interface HomeTextProps {
    onFontsLoaded: () => void;
}

const HomeText: React.FC<HomeTextProps> = (({ onFontsLoaded }) => {

    const [fontLoaded, setFontLoaded] = useState(false);
    
    let localRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const observer = new FontFaceObserver('Arial-MT-Bold');
        observer.check().then(() => {
            setFontLoaded(true);
            onFontsLoaded();
        })
    }, [onFontsLoaded]);

    useLayoutEffect(() => {
        if (localRef.current) {
          const tl = gsap.timeline({
            repeat: 0,
            delay: 1,
           
          });
      
          const spans = localRef.current.querySelectorAll('span');
      
          if (spans.length) {
            tl.fromTo(spans, {
              opacity: 0.25,
              x: -40,
              filter: "blur(10px)",
            },
            {
              opacity: 1,
              x: -0, // Move each span from 20px down
              filter: "blur(0px)",
              duration: 1, // Animation duration for each span
              stagger: 0.25, // Delay between each span's animation
            }
        );
          }
        }
    }, []);

    return  (
        <HomeContentDiv className="container">
                <HomeHeading ref={localRef}>
                    <span>frontend</span>
                    <span>developer</span>
                    <span>designer</span>
                </HomeHeading>
                <ScrollDown>
                    <CircleArrowContainer src={AnglesDown} alt="Scroll Down" />
                    <HomeParagraph>Graphic design and web development I have experience doing a wide range of projects. Take a moment to browse my portfolio.</HomeParagraph>
            </ScrollDown>
        </HomeContentDiv>
        );

    });

export default HomeText;