import React from 'react';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import DevItemButton from './DevItemButton';

import { DevItem } from '../../assets/types/types';

type PortfolioItemProps = {
    index: number;
    item: DevItem;
    isExpanded: boolean;
    onExpand: (index: number) => void;
}

const PortfolioItem = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-items: center;
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    border: 0px solid #33333375;
    padding: 5px;
    border-radius: 25px;
    background: #390872cd;
    transition: all 0.15s ease-in-out;
    animation: slideTexter 1000ms forwards linear; 

    &:hover {
        background: #390872; 
    }

    @media (min-width: 1000px){
        padding: 15px 15px;
    }

`;

const PortfolioItemText = styled.div`
    display: flex; 
    flex-direction: column;
    gap: 10px;
    padding: 15px;
`;

const ExpandButton = styled.p`
    font-family: 'Roboto-Black';
    width: fit-content;
    color: #ffffff;
    margin-bottom: 15px;
    margin-left: 15px;
    cursor: pointer;
    font-size: .9rem; /* or 1.25em */
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: .1rem;
    color: #d2e103;

    &:hover {
        color: #d2e103; 
    }
`;

const PortfolioItemTitle = styled.h3`
    font-family: 'Oswald';
    font-size: 2rem;
    text-transform: capitalize;
    color: #ffffff;
    line-height: 120%;
    letter-spacing: 0.15rem;
`;

const PortfolioItemType = styled.p`
    font-family: 'Roboto-Regular';
    font-size: 1rem;
    text-transform: capitalize;
    color: rgb(124, 124, 124);
    text-transform: uppercase;
    border-bottom: 6px solid #507666;
    padding-bottom: 10px;

    ${PortfolioItem}:hover & {
        border-bottom: 6px solid #38ffa9; 
    }
`;

const PortfolioItemDescription = styled.p`
    font-family: 'Rubik';
    font-size: 1rem;
    line-height: 160%;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 10px;
    flex-grow: 1;
    height: 100%;
    hyphens: auto;
    background-color: #15002784;
    padding: 25px;
    border-radius: 40px 0px 40px 0px;
`;

const FeaturesList = styled.ul`
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    list-style: none;
    font-family: 'Rubik';
    font-size: .75rem;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 0.06rem;
    padding: 20px;
    margin: 0px;
    margin-bottom: 10px;
    border-radius: 15px;
 
`;

const FeaturesItem = styled.li`
    flex-grow: 1;
    padding: 10px 0px 0px 10px;
    border-top: 1px dashed #d3cbd853;
    line-height: 120%;

    &:first-child {
        border: 0;
        padding-top: 0px;
    }
`;

const PortfolioTechList = styled.ul`
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    list-style: none;
    font-family: 'Arial-MT-Bold';
    font-size: .7rem;
    text-transform: uppercase;
    color: #ffffff;
    letter-spacing: 0.1rem;
    padding: 0px 0px;
    margin-top: 10px;
`;

const ListItem = styled.li`
    flex-grow: 1;
    background-color: #440097;
    padding: 6px 15px;
    border-radius: 25px;
    text-align: center;
`;


const DevPortfolioItem: React.FC<PortfolioItemProps> = ({ index, item, isExpanded, onExpand }) => {

    const handleExpand = () => {
        onExpand(index);
    };

    return (
        <PortfolioItem key={item.id}>
            <PortfolioItemText>
                <PortfolioItemTitle>{item.title}</PortfolioItemTitle> 
                <PortfolioItemType>{item.type}</PortfolioItemType>
                <PortfolioItemDescription>{item.description}</PortfolioItemDescription>
                {isExpanded ? (``):( <ExpandButton onClick={() => {handleExpand()}}><FontAwesomeIcon icon={faAngleDown} /> expand</ExpandButton> )}   
                {isExpanded && (
                    <FeaturesList>
                        {item.features.map((feature, innerIndex) => (
                            <FeaturesItem key={innerIndex}>{feature}</FeaturesItem>
                        ))}
                    </FeaturesList>
                    )}
                <div style={{display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'stretch'}}>
                        <DevItemButton $externalUrl={item.link}>Launch</DevItemButton>
                    <DevItemButton $externalUrl={item.github}>Github</DevItemButton>
                </div>               
                <PortfolioTechList>
                    {item.tech.map((tech, index) => (
                        <ListItem key={index}>{tech}</ListItem>
                    ))}
                </PortfolioTechList>               
            </PortfolioItemText>
        </PortfolioItem>
    );
}

export default DevPortfolioItem;