import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


import rocket from '../../assets/images/rocket.png';
import spacemanColorImage from '../../assets/images/spaceman-color.png'
import spacemanPlainImage from '../../assets/images/spaceman-plain.png';
import starLarge from '../../assets/images/star-large.png';
import starMedium from '../../assets/images/star-medium.png';
import starSmall from '../../assets/images/star-small.png';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


interface ImageProps {
    top: string;
    left: string;
}

// Rocket
const RocketIMG = styled.img`
    display: none;
    position: absolute;
    scale: 0.75;
    z-index: 100;

    filter: blur(100px) brightness(250%);

    @media (min-width: 1000px){
        top: -0px; 
        left: -19vw;
        display: block;
    };

    @media (min-width: 1200px){
        top: -0px; 
        left: -6vw;
        display: block;
    };

    @media (min-width: 1500px) {
        position: absolute;
        top: -0px; 
        left: -30px;

    }
`;


// Spaceman color
const SpacemanIMG = styled.img`
    display: none;
    position: absolute;
    z-index: 99;

    @media (min-width: 1200px) {
        display: block;
    }
`;

// Star large

const StarLargeIMG = styled.img<ImageProps>`
    display: none;
    position: absolute;
    top: ${props => props.top};  
    left: ${props => props.left}; 

    @media (min-width: 1500px) {
        display: block;
    }
`;

// Star Medium

const StarMediumIMG = styled.img<ImageProps>`
    display: none;
    position: absolute;
    top: ${props => props.top};  
    left: ${props => props.left}; 

    @media (min-width: 1500px) {
        display: block;
    }

`;


// Star Small
const StarSmallIMG = styled.img<ImageProps>`
    display: none;
    position: absolute;
    top: ${props => props.top};  
    left: ${props => props.left}; 
    @media (min-width: 1500px) {
        display: block;
    }

`;

const HomeHeroCircle = styled.img<ImageProps>`
  display: none; 
  position: absolute;
  top: ${props => props.top};  
  left: ${props => props.left};
  border-width: 7px;
  border-color: rgb(219, 219, 219);
  border-style: solid;
  border-radius: 50% 00% 50% 0%;
  background-image: linear-gradient(
    45deg,
    rgb(246, 67, 5),
    rgb(123, 70, 130), 
    rgb(0, 72, 255), 
    rgb(3, 109, 178),
    rgb(39, 146, 128), 
    rgb(255, 255, 0), 
    rgb(246, 67, 5), 
    rgb(123, 70, 130), 
    rgb(0, 72, 255), 
    rgb(3, 109, 178),
    rgb(39, 146, 128),
    rgb(255, 255, 0)
  );
  background-size:  1000% 1000%;  
  animation: gradientAnimation 15s linear alternate infinite; 
  width: 475px;
  height: 450px;
  z-index: -1;
  justify-self: center;

  @media (min-width: 1500px) {
        display: block;
    }
`;

const AfterGlow = styled.img<ImageProps>`
  display: none; 
  position: absolute;
  top: ${props => props.top};  
  left: ${props => props.left};
  border-radius: 50% 00% 50% 0%;
  background-image: linear-gradient(
    45deg,
    rgb(246, 67, 5),
    rgb(123, 70, 130), 
    rgb(0, 72, 255), 
    rgb(3, 109, 178),
    rgb(39, 146, 128), 
    rgb(255, 255, 0), 
    rgb(246, 67, 5), 
    rgb(123, 70, 130), 
    rgb(0, 72, 255), 
    rgb(3, 109, 178),
    rgb(39, 146, 128),
    rgb(255, 255, 0)
  );
  background-size:  1000% 1000%;  
  animation: gradientAnimation 15s linear alternate infinite; 
  width: 570px;
  height: 570px;
  z-index: -1;
  justify-self: center;
  /* box-shadow: 50px 50px 10px rgb(0, 0, 0), 0px 0px 50px rgb(255, 255, 255); */
  filter: blur(60px);
  opacity: 0.65;

  @media (min-width: 1500px) {
        display: block;
    }
  `;

const HomeHeroContainer = styled.div`
    position: relative;
    z-index: 5;
    scale: 1;
`;

interface HomeHeroProps {
    onImagesLoaded: () => void;
}

const HomeHero: React.FC<HomeHeroProps> = ( { onImagesLoaded }) => {
    const [isImage1Loaded, setIsImage1Loaded] = useState(false);
    const [isImage2Loaded, setIsImage2Loaded] = useState(false);
    const [isImage3Loaded, setIsImage3Loaded] = useState(false);
    const [isImage4Loaded, setIsImage4Loaded] = useState(false);
    const [heroLoaded, setHeroLoaded] = useState(false);

    let colorSpacemanRef = useRef<HTMLImageElement>(null);
    let whiteSpacemanRef = useRef<HTMLImageElement>(null);
    let rocketImgRef = useRef<HTMLImageElement>(null);
    const starsImgRef = useRef<HTMLImageElement[]>([]);

    const addToRefArray = (el: HTMLImageElement | null) => {
        if (el && !starsImgRef.current.includes(el)) {
            starsImgRef.current.push(el);
        }
    };

    const preloadImage = (src: string, setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>) => {
        const image = new Image();
        image.src = src;
        image.onload = () => setIsLoaded(true);
    };

    useEffect(() => {
        preloadImage(rocket, setIsImage1Loaded);
        preloadImage(spacemanColorImage, setIsImage2Loaded);
        preloadImage(spacemanPlainImage, setIsImage3Loaded);
        preloadImage(starLarge, setIsImage4Loaded);   
    }, []);

    useEffect(() => {
        if (isImage1Loaded && isImage2Loaded && isImage3Loaded && isImage4Loaded ) {
            onImagesLoaded();
            setHeroLoaded(true);
        };
    }, [isImage1Loaded, isImage2Loaded, isImage3Loaded, isImage4Loaded, onImagesLoaded]);

    useLayoutEffect(() => {
        if (colorSpacemanRef.current && whiteSpacemanRef.current) {
            const spacemenTimeline = gsap.timeline({
                repeat: -1,
                delay: 0,
                yoyo: true,
            });
            // First animation (colorSpaceman)
            spacemenTimeline.set([colorSpacemanRef.current], 
            {
            scale: 0.7,
            opacity: 1,
            y: -50, 
            x: 40,
            }
        );
        spacemenTimeline.set([whiteSpacemanRef.current], 
            {
            scale: 0.7,
            opacity: 1,
            y: 20, 
            x: 580,
            }
        );
        spacemenTimeline.to([colorSpacemanRef.current], {
            y: 100, 
            x: 40, 
            duration: 10, 
            yoyo: true,
            ease: "power1.inOut",
          }, 1);
        spacemenTimeline.to([whiteSpacemanRef.current], {
            y: -40, 
            x: 580, 
            duration: 10, 
            yoyo: true,
            ease: "power1.inOut",
        }, 0);
    }}, []);

    useLayoutEffect(() => {
        if (starsImgRef.current.length > 0) {

            const starsTimeline = gsap.timeline({
                repeat: 0,
                delay: 1,
            });

          starsImgRef.current.forEach((star: HTMLImageElement) => {
                starsTimeline.fromTo(star, 
                    {
                        opacity: 0, 
                        y: -150, 
                    },
                    {   
                        opacity: 1,
                        y: 100, 
                        duration: 0.25,
                        ease: "elastic.out(0.51,0.3)",
                    }
                ); 
          });
        }    
    }, []);

    useLayoutEffect(() => {
        if (rocketImgRef.current) {
            const rocketTimeline = gsap.timeline();
            // First animation (colorSpaceman)
        rocketTimeline.to([rocketImgRef.current], {
            y: -150, 
            x: 0,
            duration: 0.5,
            ease: "back.out(0.5)",
            filter: "blur(0px) brightness(100%)",
          }, 0.75);
    }}, []);
    

    return (     
        <HomeHeroContainer> 
            <section>
                <SpacemanIMG ref={colorSpacemanRef} src={spacemanColorImage} alt="Logo" />
                <SpacemanIMG ref={whiteSpacemanRef}  src={spacemanPlainImage} alt="Logo" />
                <StarLargeIMG ref={addToRefArray} top="150px" left="700px" src={starLarge} alt="star" />
                <StarMediumIMG ref={addToRefArray} top="250px" left="0px" src={starMedium} alt="star" />
                <StarMediumIMG  ref={addToRefArray} top="-300px" left="600px" src={starMedium} alt="star" />
                <StarSmallIMG ref={addToRefArray} top="-150px" left="200px" src={starSmall} alt="star" />
                <RocketIMG ref={rocketImgRef} src={rocket} alt="Logo" />
                <HomeHeroCircle  top="10px" left="220px" />
                <AfterGlow  top="0px" left="140px" />
            </section> 
        </HomeHeroContainer>      
    )};

export default HomeHero;