import React from "react";

import styled from 'styled-components';

import MarkLogo from '../../assets/images/mark-logo.svg';



interface PortfolioIntroComponentProps {
    children: React.ReactNode;
}

const PortfolioHeadingContentContainer = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    align-items: center;

    @media (min-width: 780px) {
        grid-template-columns: 1fr 5fr;
        gap: 50px;
    };
`;

const PortfolioIntro = styled.p`
    font-family: 'Roboto-Thin';
    font-size: clamp(1.1rem, 1.25vw, 1.1rem);
    color: #ffffff;
    max-width: 1000px;
    line-height: 175%;
    letter-spacing: 0.04rem;
    padding: 0px 20px;

    @media (min-width: 780px) {
        border-left: 3px solid #ffffff21;
        padding: 20px 40px;
    };
`;

const PortfolioIntroComponent: React.FC<PortfolioIntroComponentProps> = ({ children }) => {

    return (
        <PortfolioHeadingContentContainer>
            <div style={{justifySelf:'center'}}>
                <img src={MarkLogo} alt="Mark Logo"></img>
            </div>
            <div>
                <PortfolioIntro>{children}</PortfolioIntro>
            </div>
        </PortfolioHeadingContentContainer>
    );
}

export default PortfolioIntroComponent;