import React from 'react';
import styled, { keyframes } from 'styled-components';

import alien from '../../assets/images/alien.png';
import smoke from '../../assets/images/smoke.png'


interface ImageProps {
    top: string;
    left: string;
}

const hover = keyframes`
0%, 100% {
    transform: translateY(0);
}
50% {
    transform: translateY(-30px) perspective(1000px) rotateX(-6deg) rotateY(8deg) rotateZ(3deg);
}
`;

const colors = keyframes`
0%, 100% {
    filter: hue-rotate(0);
}
50% {
    filter: hue-rotate(360deg);
}
`;

// Smoke
const SmokeIMG = styled.img`
    animation: ${colors} 15s infinite; 
    max-width: 1200px;
    min-width: 800px;
    width: 50vw;
`;

const SmokeImage: React.FC<ImageProps> = () => {
    return (
        <SmokeIMG src={smoke} alt="planet" />
    );
} 

// Alien
const AlienIMG = styled.img`
    position: absolute;
    animation: ${hover} 15s infinite cubic-bezier(0.25, 0.46, 0.45, 1.94); 
    max-width: 800px;
    min-width: 550px;
    width: 30vw;
`;

const AlienImage: React.FC = () => {
    return (
        <AlienIMG src={alien} alt="rocketman" />
    );
} 


const DevHeroContainer = styled.div`
    position: relative;
    display: none;
    max-width: 1500px;

    @media (min-width: 1000px){
        display: flex;
        justify-content: center;
    }
`;

const HomeHero = () => {
    return(
        <DevHeroContainer>
            <SmokeImage top="50px" left="-100px" />
            <AlienImage />
        </DevHeroContainer>
    );
}

export default HomeHero;