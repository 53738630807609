import React from 'react';
import styled, { keyframes } from 'styled-components';

import rocketman from '../../assets/images/rocketman.png';
import planetImage from '../../assets/images/planet.png'


interface ImageProps {
    top: string;
    left: string;
}


// Planet
const PlanetIMG = styled.img<ImageProps>`
    position: absolute;
    top: 0;  
    left: 0; 
    width: 40vw;
    max-width: 1000px;
    min-width: 700px;
    height: auto;
    z-index: 8;
`;

const PlanetImage: React.FC<ImageProps> = ({top, left}) => {
    return (
        <PlanetIMG top={top} left={left} src={planetImage} alt="planet" />
    );
} 

// Rocket Man
const rotate = keyframes`
0%, 100% {
    transform: translateY(0);
}
50% {
    transform: rotate(-15deg) translateY(100px);
}
`;

const RocketManIMG = styled.img<ImageProps>`
    animation: ${rotate} 35s infinite alternate-reverse ease-in-out; 
    width: 40vw;
    max-width: 1000px;
    min-width: 600px;
    height: auto;
    z-index: 9;
`;

const RocketManImage: React.FC<ImageProps> = ({top, left}) => {
    return (
        <RocketManIMG top={top} left={left} src={rocketman} alt="rocketman" />
    );
} 

const DesignHeroContainer = styled.div`
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 5;
    max-width: 1200px;

    @media (min-width: 1000px){
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const HomeHero = () => {
    return(
        <DesignHeroContainer>
                <RocketManImage top="-80px" left="-50px"></RocketManImage> 
                <PlanetImage top="-50px" left="-80px"></PlanetImage>
        </DesignHeroContainer>
    );
}

export default HomeHero;