import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import HomeHead from '../content/home/HomeHead';
import HomeTextBlock from '../content/home/HomeTextBlock';
import DesignHero from '../content/home/DesignHero';
import DevHero from '../content/home/DevHero';
import Loading from '../components/loading/Loading';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);


const HomeContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    overflow: visible;
    overflow-x: hidden;
    padding-bottom: 100px;
    backdrop-filter: blur(3px);
    width: 100%;
`;

const Header = styled.section`  
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Bottom = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 1100px){
        grid-template-columns: 1fr 1fr;
    }

`;

const Inner = styled.section`
    background-position: -100% -100%;
    display: grid;
    grid-template-rows: auto;
    height: fit-content;
    width: 90%;
    max-width: 1500px;
`;

const Home: React.FC = () => {

    const [assetsLoaded, setAssetsLoaded] = useState(false)

    let headerComponentRef = useRef<HTMLDivElement>(null);

    const assetsLoadedCallback = () => {
        const timeout = setTimeout(() => {
            setAssetsLoaded(true);
        }, 500);

        return () => clearTimeout(timeout)
    }

    // useGSAP(() => {
    //     if (headerComponentRef.current) {
    //         const bottomComponentTimeline = gsap.timeline({
    //             repeat: 0,
    //             delay: 0,
    //             scrollTrigger: {
    //                 start: 'top top',
    //                 scrub: 0.5,
    //                 end: '+=1600',
    //             }
    //         });
    //         bottomComponentTimeline.to([headerComponentRef.current], {
    //             y: '-400', 
    //             ease: "power1.inOut",
    //         });
    // }}, []);

    return (
        <>
            <HomeContainer>
                <Inner>
                    <Header>
                        <HomeHead onAssetsLoaded={assetsLoadedCallback}></HomeHead>
                    </Header>
                    <Bottom ref={headerComponentRef}>        
                        <DesignHero />
                        <HomeTextBlock
                            heading="keep it simple, but not too simple."
                            paragraph="I enjoy combining typography and images to create engaging and informative marketing materials. Web or print I have experience designing everything a business needs."
                            link="/design"
                        >view design</HomeTextBlock>
                    </Bottom>
                    <Bottom>
                        <HomeTextBlock
                            heading="html5, css, javascript, react, php, oh my..."
                            paragraph="The simple business website has evolved into a complicated web application with many integrated parts. I spent the last three years building my knowledge and experience putting the parts together building web applications."
                            link="/dev"
                        >view dev</HomeTextBlock>
                        <DevHero />
                    </Bottom>
                </Inner> 
            </HomeContainer>
            <Loading visible={!assetsLoaded} />
        </>
    );
};

export default Home;