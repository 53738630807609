import React from 'react';
import styled from 'styled-components';


const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
`;

const HeroHead = styled.section`
    margin-top: 100px;
`;

const IntroText = styled.h1`
    position: relative;
    font-family: 'Acumin-Thin';
    text-transform: uppercase;
    font-size: 13.25vw;
    letter-spacing: -.2rem;
    width: fit-content;
    z-index: 1;
    color: #e6e6e6;
    white-space: nowrap;

    &::after {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 15px;
        background-color: #51009d;
        z-index: -1;
    }

    span {
        color: rgb(255, 217, 0);
    }

    @media (min-width: 500px) {
        font-size: 12vw;

        &::after {
            bottom: 5px;
        }
    }

    @media (min-width: 600px) {
        &::after {
            bottom: 17px;
        }
    }

    @media (min-width: 1000px) {
        font-size: 7vw;

        &::after {
            bottom: 15px;
        }
    }

    @media (min-width: 1500px) {
        font-size: 7vw;

        &::after {
            bottom: 25px;
        }
    }

    @media (min-width: 1800px) {
        font-size: 6vw;
    }

    @media (min-width: 2100px) {
        font-size: 8.25rem;
    }
`;

const Text = styled.p`
    font-family: 'Roboto-Thin';
    font-size: clamp(0.9rem, 2vw, 1.25rem);
    font-weight: 400;
    line-height: 160%;
    letter-spacing: .03rem;
    color: #e6e6e6;
    max-width: 1000px;
`;


const Intro = () => {
    return(
        <Section>
            <HeroHead>
                <IntroText>simple<span>,</span> but not</IntroText>
                <IntroText>too simple<span>.</span></IntroText>
            </HeroHead>
            <Text>I enjoy combining typography and images to create engaging and informative marketing materials. Web or print I have experience designing everything your business needs.</Text>
        </Section>
    );
}

export default Intro;