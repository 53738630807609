import React, { useState } from 'react';
import styled from 'styled-components';

import { Masonry } from '@mui/lab';

import DevPortfolioItem from './DevPortfolioItem';

import { DevItem } from '../../assets/types/types';

type PortfolioContainerProps = {
    data: DevItem[];
}

const PortfolioContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PortfolioInner = styled.section`
    max-width: 2000px;
    overflow: hidden;
    width: 90%;
`;

const DevPortfolio: React.FC<PortfolioContainerProps> = ({ data }) => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(1); // Initially no item is expanded

    const handleExpand = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle expanded state
    };

    return (
        <PortfolioContainer>
            <PortfolioInner>
                <Masonry columns={{ xs: 1, lg: 2, xl: 3 }} spacing={{ xs: 1.5, sm: 2, md: 0, lg: 3, xl: 3 }}>
                    {data.map((item: DevItem, index) => (
                        <DevPortfolioItem
                            key={index}
                            index={index}
                            item={item}
                            isExpanded={expandedIndex === index}
                            onExpand={handleExpand}
                        />
                    ))}
                </Masonry>
            </PortfolioInner>
        </PortfolioContainer>
    );
};

export default DevPortfolio;