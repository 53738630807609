import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom'; 



interface HomeTextBlockProps {
    heading: string;
    paragraph: string;
    link: string;
    children: ReactNode;
}

const HomeContentDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    z-index: 10;
    max-width: 600px;
    min-width: 550px;
`;

const HomeHeading = styled.h1`
    font-family: 'Arial-MT-Bold';
    font-size: 3rem;
    margin-left: -20px;
    padding: .4rem 1rem .4rem 1rem;
    white-space: pre-wrap;
    line-height: 100%;

    @media (min-width: 1500px) {
        font-size: 5rem;
    }
`;


const HomeParagraph = styled.p`
    font-family: 'Roboto-Thin';
    font-size: 1rem;
    line-height: 160%;
    letter-spacing: 0.05rem;
`;

const HomeButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial-MT-Bold';
    font-size: 1.05rem;
    color: #fff;
    width: 50%;
    max-width: 200px;
    height: 50px;
    border-radius: 25px 130px 130px 25px;
    align-self: start;
    overflow: hidden;
    cursor: pointer;
    transition: all 200ms ease;
    backdrop-filter: blur(3px);
    background-color: #333;
    border-left: 5px solid #6200ff;

    text-transform: uppercase;
    
    &:hover {
        transform: translateX(10px);
        background-color: #440097;
        border-left: 5px solid #38ffa9;
    }
`;

const HomeTextBlock: React.FC<HomeTextBlockProps> = ({ heading, paragraph, link, children}) => {

    const navigate = useNavigate();

    const loadPortfolio = () => {
        navigate(link);
    };

    return (
        <HomeContentDiv>
            <HomeHeading>{heading}</HomeHeading>
            <HomeParagraph>{paragraph}</HomeParagraph>
            <HomeButton onClick={loadPortfolio}>{children}</HomeButton>
        </HomeContentDiv>
    );

};


export default HomeTextBlock;