import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DesignItem } from '../assets/types/types';

import DesignHero from '../content/home/DesignHero';

// import adobeCCLogo from '../assets/images/cc-logo.svg';

import Intro from '../content/design/DesignIntro';
// import DesignCloud from '../content/design/design-cloud';
import DesignPortfolio from '../content/design/DesignPortfolio';

import Loading from '../components/loading/Loading';

const DesignPageContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    min-width: 100%;
    gap: 50px;
    margin-bottom: 50px;
    overflow-x: hidden;
`;

const HeaderSection = styled.header`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    min-height: 65vh;
    width: 90%;
    max-width: 1725px;
    margin-top: 40px;

    @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr; 
    }
`;

const Design = () => {

    const [data, setData] = useState<DesignItem[]>([]);

    const [assetsLoaded, setAssetsLoaded] = useState(false)

    const loadData = async () => {
        try {
            const dataLoaded: Response = await fetch('/assets/data/design.json');
            if (!dataLoaded.ok) {
                throw new Error('Failed to fetch data');
            }
            const dataJSON: DesignItem[] = await dataLoaded.json();
            setData(dataJSON);           
            setTimeout(() => {
                setAssetsLoaded(true);
            }, 500);
        } catch (error) {
            console.error('Error loading data:', error);
        };  
    };

    useEffect(() => {
        loadData();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    // useEffect(() => {
    //     // Event listener to track scroll position
    //     const handleScroll = () => {
    //       const bgPosition = `${window.scrollY * 0.2}px`;
    //       setBackgroundPositionX(bgPosition);
    //     };
    
    //     // Attach the event listener
    //     window.addEventListener('scroll', handleScroll);
    
    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);

    return (
        <>
            <Loading visible={!assetsLoaded} />
            <DesignPageContainer>
                <HeaderSection>
                    <Intro />
                    <DesignHero />
                    {/* <DesignCloud /> */}
                </HeaderSection>
                <DesignPortfolio data={data} />
            </DesignPageContainer>
        </>
    );
};

export default Design;