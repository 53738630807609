import React, {useState, MouseEvent} from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

type LinkButtonProps = {
    children: React.ReactNode;
    $externalUrl?: string;
    onClick?: () => void;
}

const StyledItemButton = styled.button`
  position: relative;
  font-size: 1rem;
  font-family: 'Arial-MT-Bold';
  color: #ffffff;
  padding: 15px 0px;
  border: none;
  background: #440097;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 10px;
  width: 50%;
  overflow: hidden;
  align-self: end;

  &:hover {
    color: #fff;
  }
`;


const StyledIcon = styled(FontAwesomeIcon)`
    margin-left: 3px;
    color: #d2e103;
    text-shadow: #000;

`;

const DevItemButton: React.FC<LinkButtonProps> = ({ $externalUrl, children }) => {
    const [isTracking, setIsTracking] = useState(false);
    const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });

      const handleMouseEnter = () => {
        setIsTracking(true);
    };

    const handleMouseLeave = () => {
        setIsTracking(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (isTracking) {
            const buttonRect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - buttonRect.left;
            const y = e.clientY - buttonRect.top;
            setCirclePosition({ x, y });
        }
    };

    const handleOpenURL = () => {
        if ($externalUrl) {
            window.open($externalUrl, '_blank');
        }
    };

    return (
        <StyledItemButton 
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        style={{ position: 'relative' }}
        onClick={handleOpenURL}>
            <div style={{position: 'relative', zIndex: '1'}}>
                {children}
                <StyledIcon icon={faCircleArrowRight} />
            </div>
        {isTracking && (
        <div
            style={{
                position: 'absolute',
                top: circlePosition.y,
                left: circlePosition.x,
                width: '220px',
                height: '220px',
                backgroundColor: 'rgb(126, 36, 253)',
                borderRadius: '50%',
                transform: 'scale(0) translate(-50%, -50%)',
                zIndex: '0',
                animation: 'growCircle 1s ease-in-out forwards',
            }}
        />
      )}
        </StyledItemButton>
    );
};

export default DevItemButton;