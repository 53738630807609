import React, {useRef, useState} from 'react';
import styled, { keyframes } from 'styled-components';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';


import AnglesDown from '../../assets/images/angles-down.svg';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const spin = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  50% {
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
`;

const IntroSection = styled.section`
    width: 100%;
    position: relative; 
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const SlideContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
    max-width: 2000px;
    height:  100vh;
    align-items: center;
    justify-items: center;
    align-content: end;
    transform: all 500ms ease-in-out;
    padding-top: 60px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    border-radius: 25px 25px 0px 0px;

    @media (min-width: 800px) {
        height: 50vh;
        box-shadow: 0px 0px 150px #02020287;
        background: linear-gradient(200deg, #5412b0fd 0%, #431697b8 24%, #180330ba 99%);
        background-position: center;
        background-size: cover;
     }
    
`;

interface TextProps {
    style: React.CSSProperties; 
}

interface TimelineProgressProps {
  $timelineProgress: string; 
}
  
const Text = styled.p<TextProps>`
    position: absolute;
    transition: all 500ms;
    font-family: 'Acumin-Thin';
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    line-height: 160%;
    letter-spacing: .025rem;
    color: #e6e6e6;
    text-align: center;
    max-width: 1000px;
    width: 90%;
    opacity: 0;

    @media (min-width: 800px) {
        font-size: 1.5rem;
     }
`;

const TimelineProgressBarContainer = styled.div`
    width: 100%;
    height: 10px;   
`;

const TimelineProgressBar = styled.div<TimelineProgressProps>`
    background-color: ${(props) => (parseFloat(props.$timelineProgress) >= 90 ? '#38ffa9' : '#fff')};
    box-shadow: 0px 0px 30px ${(props) => (parseFloat(props.$timelineProgress) >= 90 ? '#38ffa9' : '#ffffff')};
    position: relative;
    height: 100%;
    width: ${(props) => `${parseFloat(props.$timelineProgress) + 10}%`};
    transition: all 500ms ease-out;
    border-radius: 0px 25px 25px 0px;
`;

const CircleArrowContainer = styled.img`
    display: inline-block;
    width: 25px;
    height: auto;
    animation: ${spin} 2s ease-in-out infinite;
`;

const slidesData: string[] = [
    `Websites today are complex applications that require numerous parts to function. I've spent the last few years gaining knowledge and experience assembling these parts into complex web applications.`,
    `As a result of COVID-19's impact on the economy, and my passion for developing web applications, I decided to enroll in the Web Development program at Georgian College. I successfully completed the program, graduating with honors on the Dean's List.`,
    `Georgian's program covered a broad range of topics related to frontend and backend web development including: HTML, CSS, Javascript, SQL, PHP, Node, React, Angular, ASP.NET, PHP, Wordpress, RESTful API, Databases, Authentication/Authorization, Frameworks, Libraries, MVC, and much more! It also emphasized soft skills like: Project Management, and User Experience.`,
    `The skills and experience I've built around developing web applications will allow me to be an asset to your team. Below is some of my recent work, please take a moment to browse these projects.`
];

const Intro: React.FC = () => {

    const [timelineProgress, setTimelineProgress] = useState<string>('0');

    const containerRef = useRef<HTMLDivElement>(null);
    const slidesRef = useRef<HTMLDivElement[]>([]);

    const containerTimeline = useRef<gsap.core.Timeline>();
    const slidesTimeline = useRef<gsap.core.Timeline>();

    const addToRefArray = (el: HTMLDivElement | null) => {
        if (el && !slidesRef.current.includes(el)) {
            slidesRef.current.push(el);
        }
    };

    useGSAP(() => {
        if (containerRef.current) {
          containerTimeline.current = gsap.timeline({});

          containerTimeline.current?.fromTo(containerRef.current, 
              {y: -30},
              {y: 0, duration: 1, delay: 0.5}
          );

          slidesTimeline.current = gsap.timeline({
            scrollTrigger: {
              trigger: containerRef.current,
              pin: true,
              pinSpacing: true,
              start: 'center center', 
              end: `+=${window.innerHeight * slidesRef.current.length}`, 
              scrub: true, 
              snap: 0,
              onUpdate: (self) => {
                setTimelineProgress((self.progress * 100).toFixed(0)); // Get progress in percentage
                
              }
            }
          });

          slidesRef.current.forEach((slide, index) => {
              if (index !== 3){
              slidesTimeline.current?.to(slide, { 
                opacity: 1, 
                duration: 0.1,
                ease: 'power1.inOut'
              }) 
              .to(slide, {
                opacity: 0, 
                duration: 0.1, 
                ease: 'power1.inOut' 
              }); 
          } else if (index === 3){
              slidesTimeline.current?.to(slide, { 
                  opacity: 1, 
                  duration: 0.1,
                  ease: 'power1.inOut'
                }) 
          }
          });
        }    
    }, []);
   
    return(
        <IntroSection ref={containerRef}>
            <SlideContainer>
                {slidesData.map((text, index) => (
                    <Text key={index} ref={addToRefArray} style={{opacity: index === 0 ? 1 : 0}}>
                        {text}
                    </Text>
                ))}
                <TimelineProgressBarContainer>
                        <TimelineProgressBar $timelineProgress={timelineProgress}/>
                </TimelineProgressBarContainer>
            </SlideContainer>
            <CircleArrowContainer src={AnglesDown} alt="Scroll Down" />
        </IntroSection>
    );
};

export default Intro;