import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DevItem } from '../assets/types/types';

import Intro from '../content/dev/DevIntro';
import DevPortfolio from '../content/dev/DevPortfolio';

import Loading from '../components/loading/Loading';

const DevContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
`;

const Dev = () => {

    const [data, setData] = useState<DevItem[]>([]);
    const [assetsLoaded, setAssetsLoaded] = useState(false)

    const loadData = async () => {
        try {
            const dataLoaded: Response = await fetch('/assets/data/dev.json');
            if (!dataLoaded.ok) {
                throw new Error('Failed to fetch data');
            }
            const dataJSON: DevItem[] = await dataLoaded.json();
            setData(dataJSON);
            setTimeout(() => {
                setAssetsLoaded(true);
            }, 500);
        } catch (error) {
            console.error('Error loading data:', error);
        };  
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Loading visible={!assetsLoaded} />
            <DevContainer> 
                <Intro />
                <DevPortfolio data={data}/>
            </DevContainer>
        </>
    );
};

export default Dev;