import React, {useState, useMemo, useEffect} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { styled } from 'styled-components';

import Particles, { initParticlesEngine } from '@tsparticles/react';
import { type Container, type ISourceOptions, MoveDirection,  OutMode } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim"; 

import Home from './views/routes/Home';
import Design from './views/routes/Design';
import Dev from './views/routes/Dev';

import Menu from './views/components/menu/Menu';
import Footer from './views/components/footer/Footer';

import backgroundImage from './views/assets/images/bg-image-light.svg';

const AppContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  background: url(${backgroundImage}), radial-gradient(circle at 40% -10%, #4c04b8 0%, rgba(48,10,92,1) 40%, rgba(0, 0, 0, 0.961) 90%);
  background-attachment: fixed, fixed;
  background-position: right top, center;
  background-size: 900px, 100%;
  background-repeat: repeat, no-repeat;
`;

const App = () => {

  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
      initParticlesEngine(async (engine) => {
        await loadSlim(engine);
          }).then(() => {
          setInit(true);
          });
  }, []);
  
  const particlesLoaded = async (container?: Container): Promise<void> => {
    // console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      fpsLimit: 60,
      particles: {
        color: {
          value: ["#ffffff"]
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.bounce,
          },
          random: false,
          speed: 0.25,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 25,
        },
        blur: {
            radius: 5, 
            enabled: true,
          },
        opacity: {
            value: {
              min: 0,
              max: 0.9
            }
          },
        rotate: {
        value: { min: 0, max: 1260 }, 
        animation: {
            enable: true, 
            speed: 1, 
            sync: false, 
        },
        direction: "clockwise", 
        path: true, 
        },
        shape: {
          type: "triangle",
        },
        size: {
            value:  {min: 1, max: 15}, 
            random: true, 
            animation: {
                enable: true,
                speed: 0.2, 
                size_min: 1, 
                sync: false, 
            },
        },
      },
      detectRetina: true,
    }),
    []
  );

  return (
    <>
      <BrowserRouter> 
        <AppContainerDiv>
        <Menu />
        {init && (
        <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
        />
        )}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/design" element={<Design />} />
            <Route path="/dev" element={<Dev />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </AppContainerDiv>
      </BrowserRouter>
    </>
  );
};

export default App;
