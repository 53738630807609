import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { DesignItem } from '../../assets/types/types';


const PortfolioItemContainer = styled.div`
    position: relative;
    overflow: hidden;
    opacity: 0;
    aspect-ratio: 16/9;
    /* padding-bottom: calc(55%);  */
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    animation: PortfolioItemScale 0.35s 1 forwards ease-in;
    border: 3px solid #333;
    width: 100%;
    max-width: 520px;

    @media (min-width: 580px) {
        background-size: 113%;

        &:hover {
            background-size: 135%; 
        }
    }

    @keyframes PortfolioItemScale {
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(5px);
            opacity: 100;
        }
    }
`;

const PortfolioItemText = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    bottom: -35%;
    padding: 15px 5px;
    height: 60%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    transition: all 0.15s ease-in-out;

    ${PortfolioItemContainer}:hover & {
        bottom: 0px;
        height: 100%;
        backdrop-filter: blur(10px);
        background: rgba(68, 68, 68, 0.7);
    }  
`;

const PortfolioItemDescription = styled.p`
    font-family: 'Roboto-Thin';
    font-size: 1rem;
    text-align: center;
    margin: 0rem 2px;
    text-transform: capitalize;
    opacity: 0;
    color: #fff;

    ${PortfolioItemContainer}:hover & {
        opacity: 1
    }  
`;

const PortfolioItemType = styled.p`
    font-family: 'Roboto-Regular';
    font-size: .75rem;
    text-align: center;
    margin: 0rem 2px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffffff;
    border-radius: 30px;
    background-color: var(--purple-bright);
    padding: 5px 45px;
`;

const PortfolioItemButton = styled.p<{}>`
    box-sizing: border-box;
    font-family: 'Arial-MT-Bold';
    font-size: .75rem;
    text-align: center;
    margin: 0rem 2px;
    text-transform: uppercase;
    background-color: rgb(255, 217, 0);
    color: #333;
    padding: .5rem 2rem;
    border-radius: 50px;
`;

const PortfolioItemGoIcon = styled(FontAwesomeIcon)`
    position: relative;
    /* animation: pulse .3s infinite; */
    left: 4px;
    transition: all .3s ease;

    ${PortfolioItemButton}:hover & {
        left: 10px;
    }  
`;

interface PortfolioItemProps {
    openLightbox: (index: number) => void;
    item: DesignItem;
    index: number;
}

const DesignPortfolioItem: React.FC<PortfolioItemProps> = ({ item, openLightbox, index }) => {

    return(
        <PortfolioItemContainer
            key={item.id}
            style={{ backgroundImage: `url(/assets/images/${item.thumbnail})`}}
            onClick={() => openLightbox(index)}
        >
            <PortfolioItemText>
                <PortfolioItemType>{item.type}</PortfolioItemType> 
                <PortfolioItemDescription>{item.description}</PortfolioItemDescription>   
                <PortfolioItemButton>
                    view
                    <PortfolioItemGoIcon icon={faCaretRight} size="1x" style={{ color: '#333'}}/>
                </PortfolioItemButton>
            </PortfolioItemText>
        </PortfolioItemContainer>
    );
}

export default DesignPortfolioItem;