import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import Lightbox from '../../components/lightbox/Lightbox';
import PortfolioItem from './DesignPortfolioItem';
import PortfolioIntroComponent from './DesignPortfolioIntro';
import PortfolioFilterButton from './PortfolioFilterButton'

import { DesignItem } from '../../assets/types/types';

type Props = {
    data: DesignItem[];
}

const PortfolioContainer = styled.section`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 500ms ease;
    max-width: 2500px;
    z-index: 5;
    width: 100%;
    gap: 10px;
    overflow-y: hidden;
`;

const PortfolioInner = styled.section`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    width: 95%;
    max-width: 2500px;
`;

const PortfolioHeadingContainer = styled.section`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const PortfolioHeading = styled.h2`
    font-family: 'Acumin-Thin';
    text-transform: uppercase;
    font-size: clamp(3rem, 5vw, 6.7rem);
    color: #e6e6e6;
`;

const FilterButtonsContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    align-self: center;
    padding: 25px;
    border-radius: 20px;

    @media (min-width: 815px) {
        flex-direction: row;
        max-width: 1000px;
        gap: 40px;
    }
`;

const PortfolioItemsContainer = styled.section`
    display: grid;
    grid-template-columns: 1fr; 
    grid-auto-flow: row;
    justify-items: center;
    gap: 15px 12px;
    width: 100%;
    max-width: 2100px;


    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr); 
    }

    @media (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr); 
    }
`;

const LoadingIcon = styled(FontAwesomeIcon)`
    font-size: 2vw;
    animation: loadingRotate 2s infinite;
    color: #fff;
    overflow: hidden;
    @keyframes loadingRotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(1turn);
        }
    }
`;

const LoadingIconContainer = styled.div`
    width: auto-fit;
`;

const DesignPortfolio: React.FC<Props> = ({ data }) => {

    const [selectedCategory, setSelectedCategory] = useState('all');
    const [filteredData, setFilteredData] = useState<DesignItem[]>([]);
    const [renderedData, setRenderedData] = useState<DesignItem[]>([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImageUrl, setLightboxImageUrl] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [nextPage, setNextPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(8);
    const [portfolioScroll, setPortfolioScroll] = useState<boolean>(false);

    const loadingRef = useRef<SVGSVGElement | null>(null);

    const portfolioRef = useRef(null);

    useEffect(() => {
        const observerB = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // Element is now visible in the viewport
                setPortfolioScroll(true);
                // You can setScroll or trigger any action here
              } else {
                setPortfolioScroll(false);
              }
            });
          },
          { threshold: 0.25 } // Intersection threshold (0.5 means 50% of the element is visible)
        );
    
        if (portfolioRef.current) {
          observerB.observe(portfolioRef.current);
        };
      }, [portfolioScroll]);

    const totalItems = data.length;
    const totalDigitalItems = data.filter(item => item.category === "digital").length;
    const totalPrintItems = data.filter(item => item.category === "print").length;

    const filterCategories = ["print", "digital", "all"];

    // Sort data for initial page load
    useEffect(() => {
        const sortedData = [...data];
        sortedData.sort((a,b) => {
            if (a.category === 'digital' && b.category === 'print') {
                return -1;
            } else if (a.category === 'print' && b.category === 'digital'){
                return 1;
            } else {
                return 0;
            }
        });
        setFilteredData(sortedData);
    }, [data]);

    // reset 
    useEffect(() => {
        setRenderedData([]);
    }, [filteredData]);

    // handle category filter button click if all show all and sort them
    const filterButtonClick = (category: string) => {
        setHasMore(true);
        setNextPage(0);
        setSelectedCategory(category);
        if (category === 'all') {
            const sortedData = [...data];
            sortedData.sort((a,b) => {
                if (a.category === 'digital' && b.category === 'print') {
                    return -1;
                } else if (a.category === 'print' && b.category === 'digital'){
                    return 1;
                } else {
                    return 0;
                }
            });
            setFilteredData(sortedData);
        } else {
            const filteredItems = data.filter((item) => item.category === category);
            setFilteredData(filteredItems);
        };
        setRenderedData([]);
    };

    // Function to load data using pagination
    const loadMoreItems = () => {
        if (!hasMore) {
            return;
        }
        const startIndex = (nextPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const newItems = filteredData.slice(startIndex, endIndex);
        setRenderedData([...renderedData, ...newItems]);
        setNextPage(nextPage + 1); 

        if (endIndex >= filteredData.length){
            setHasMore(false);
        };
    };

    // Intersection Observer
    const handleObserver = (entities: IntersectionObserverEntry[]) => {
        const target = entities[0];
        if (target.isIntersecting) {
          setTimeout(loadMoreItems, 5);
        }
    };
    
    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, {
          root: null,
          rootMargin: '0px',
          threshold: .25,
        });
    
        if (hasMore && loadingRef.current) {
          observer.observe(loadingRef.current);
        }
    
        return () => observer.disconnect();
    });
    

    const openLightbox = (index: number) => {
        const imageUrl = renderedData[index].full;
        const imageTitle = renderedData[index].title;
        setLightboxImageUrl(`/assets/images/${imageUrl}`);
        setImageTitle(imageTitle);
        setCurrentLightboxIndex(index);
        setLightboxOpen(true);    
    }

    const handleNext = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        const nextIndex = (currentLightboxIndex + 1) % renderedData.length;
        setCurrentLightboxIndex(nextIndex);        

        const imageUrl = renderedData[nextIndex].full;
        const imageTitle = renderedData[nextIndex].title;

        setLightboxImageUrl(`/assets/images/${imageUrl}`);
        setImageTitle(imageTitle);
    };
      
      const handlePrevious = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        const previousIndex = (currentLightboxIndex - 1 + renderedData.length) % renderedData.length;
        setCurrentLightboxIndex(previousIndex);

        
        const imageUrl = renderedData[previousIndex].full;
        const imageTitle = renderedData[previousIndex].title;

        setLightboxImageUrl(`/assets/images/${imageUrl}`);
        setImageTitle(imageTitle);
    };

    const closeLightbox = () => {
        setLightboxImageUrl('');
        setLightboxOpen(false);
    };

    let headerIntro = "Growing up I noticed graphic design being used everywhere in media and advertising which introduced me to graphic design as a career. Over the years I've gained a wide range of experience in digital and print design, designing everything from cnc stone carvings to websites. My design style has a wide range and I will adapt it to suit the needs of your business. Below is a sample of the work i've done, please take a moment to have a look and consider me for your open graphic design related position."

    return(
        <>
            {lightboxOpen && (
                <Lightbox $imageTitle={imageTitle} $imageUrl={lightboxImageUrl} onNext={handleNext} onPrev={handlePrevious} onClose={closeLightbox}></Lightbox>
            )}
            <PortfolioContainer ref={portfolioRef} style={portfolioScroll ? { borderRadius: 0 } : {}}>
                <PortfolioInner>
                    <PortfolioHeadingContainer>
                        <PortfolioHeading>Digital<span style={{position: 'relative', color:'rgb(255, 217, 0)', bottom: '11px', fontSize: '0.8em'}}>+</span>Print</PortfolioHeading>
                        <PortfolioIntroComponent>{headerIntro}</PortfolioIntroComponent>
                        <FilterButtonsContainer>
                            {filterCategories.map((category: string) => {
                                return(
                                    <PortfolioFilterButton $selected={selectedCategory === category} onClick={() => filterButtonClick(category)}>
                                        {category} ({category === 'print' ? totalPrintItems : category === 'digital' ? totalDigitalItems : totalItems})
                                    </PortfolioFilterButton>
                                )
                            })}
                        </FilterButtonsContainer>
                </PortfolioHeadingContainer>
                <PortfolioItemsContainer>
                        {renderedData.map((item: DesignItem, index: number) => (
                            <PortfolioItem
                                key={item.id}
                                index={index}
                                item={item}
                                openLightbox={() => openLightbox(index)}
                            />
                        ))}
                    </PortfolioItemsContainer>
                    { hasMore && <LoadingIconContainer> <LoadingIcon icon={faSpinner} ref={loadingRef}/> </LoadingIconContainer>}
                </PortfolioInner>
            </PortfolioContainer>
        </>
    );
}

export default DesignPortfolio;